import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LeaguePage from './pages/League';
import PUG from './pages/PUG';
import Home from './pages/Home';
import Redirect from './pages/Redirect';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function App() {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='league' index element={<LeaguePage />} />
          <Route path='pug' element={<PUG />} />
          <Route path='redirect' element={<Redirect />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
