import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {Grid} from '@mui/material';
import LeagueForm from '../components/LeagueForm';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const League = () => {
  const postUrl = `https://kingsleague.us21.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  const backgroundImageMD = {
    backgroundImage: 'url(/background2.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: (t) =>
      t.palette.mode === 'light'
        ? t.palette.grey[50]
        : t.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        sm={false}
        md={7}
        sx={backgroundImageMD}
      />
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <LeagueForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </Grid>
  );
};

export default League;
