import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Puff } from 'react-loading-icons'
import TiktokPixel from 'tiktok-pixel';

const Redirect = () => {

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await TiktokPixel.init(process.env.REACT_APP_PIXEL_ID);
    TiktokPixel.pageView();
    window.setTimeout(function(){
      window.location.href = "https://kingsleague.ca/pug";
    }, 3000);
  }


  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "90vh"
      }}
      alignItems="center"
      justify="center"
    >
      <Box 
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Typography variant="h4" textAlign={'center'}>Thank you for registering!</Typography>
        <Typography variant="body1">Redirecting to game page...</Typography>
        <Puff stroke="#000000" fill="#000000" speed={1} style={{marginTop: '10px'}}/>
      </Box>
    </Grid>
  );
}

export default Redirect;