import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)(() => ({
  background: 'white',
  borderRadius: '20px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& label.MuiInputLabel-shrink': {
    marginTop: "10px"
  },
}));

export default StyledTextField;
