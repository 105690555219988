import * as React from 'react';
import axios from 'axios';
import { Alert, Box, Paper, Typography } from '@mui/material';
import Background from './Background';
import Copyright from './Copyright';
import StyledButton from './Button';
import StyledTextField from './TextField';
import SendIcon from '@mui/icons-material/Send';

const PUGForm = ({ status, message, onValidated }) => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    status !== 'sending' && setLoading(false);
    if (status === 'success') {
      clearForm();
      window.setTimeout(function(){
        window.location.href = "/redirect";
      }, 1000);
    }
  }, [status]);

  const onFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const onLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const clearForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
  };

  const register = async () => {
    setLoading(true);
    await onValidated({
      EMAIL: email,
      FNAME: firstName,
      LNAME: lastName,
      tags: '2819429'
    });

    if(status === 'success') {
      await axios.post('/pug/emails', {
          First_Name: firstName,
          Email: email,
      }).catch((err) => {
          console.log(err);
      })
    }
  };

  return (
    <Background item sm={12} md={5} component={Paper} elevation={6} square>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Box
          component='img'
          src='logo_silver.png'
          alt='TKL'
          sx={{
            width: '25vw',
            height: 'auto',
            marginBottom: '1rem',
            '@media (max-width: 600px)': {
              width: '70vw',
              marginBottom: '0.5rem',
            },
          }}
        />
        <Typography
          component='h1'
          variant='h5'
          sx={{
            my: '1rem',
            textAlign: 'center',
            fontFamily: 'Helvetica',
            textShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
            textTransform: 'uppercase',
            letterSpacing: '0.1em',
            color: '#fff',
            '@media (max-width: 600px)': {
              letterSpacing: '0.05em',
              my: '0.5rem',
              fontSize: '1.2rem'
            },
          }}
        >
          Sign up to play TKL Pick-up Games!
        </Typography>
        <Box
          sx={{
            mt: 1,
          }}
        >
          <StyledTextField
            margin='normal'
            required
            fullWidth
            id='firstName'
            label='First Name'
            name='firstName'
            autoComplete='firstName'
            value={firstName}
            onChange={onFirstNameChange}
          />
          <StyledTextField
            margin='normal'
            required
            fullWidth
            id='lastName'
            label='Last Name'
            name='lastName'
            autoComplete='lastName'
            value={lastName}
            onChange={onLastNameChange}
          />
          <StyledTextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            value={email}
            onChange={onEmailChange}
          />
          <StyledButton
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2, height: '3rem' }}
            disabled={!firstName || !lastName || !email}
            onClick={register}
          >
            <span>Register</span>
          </StyledButton>
          <Copyright sx={{ mt: 5 }} />
          {status === 'success' && (
            <Alert sx={{ mt: 2 }} severity='success'>
              Thank you for registering!
            </Alert>
          )}
          {status === 'error' && (
            <Alert sx={{ mt: 2 }} severity='error'>
              {message}
            </Alert>
          )}
        </Box>
      </Box>
    </Background>
  );
};

export default PUGForm;
