import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

const StyledButton = styled(LoadingButton)(() => ({
  background: 'linear-gradient(to right, #4CAF50, #388E3C)',
  borderRadius: '20px',
  color: 'white',
  padding: '12px 24px',
  '&:hover': {
    background: 'linear-gradient(to right, #388E3C, #4CAF50)',
  },
  '&:disabled': {
    background: '#8DE891',
    color: '#757575',
  },
}));

export default StyledButton;
