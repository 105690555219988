import { Typography, Link } from "@mui/material";

const Copyright = (props) => {

    return (
      <Typography variant="body2" color={"#fff"} align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://kingsleague.ca/">
          TKL
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

export default Copyright;