import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const BackgroundGrid = styled(Grid)(({theme}) => (
  {
  position: 'relative',
  '&::before': {
    content: "''",
    background: 'linear-gradient(to right, #3B3B3B 0%, #4d4d4d 50%, #3B3B3B 100%);',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('md')]: {
      background: `linear-gradient(to bottom right, rgba(70, 70, 70, 0.8), rgba(70, 70, 70, 0.2)), url('/background2.jpg')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}));

export default BackgroundGrid;