import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

const StyledSelect = styled(Select)(() => ({
  background: 'white',
  borderRadius: '20px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

export default StyledSelect;
