import { MenuItem, FormControl } from '@mui/material';
import StyledSelect from './Select';
import StyledInputLabel from './InputLabel';

// eslint-disable-next-line
const LeagueSelect = ({ league, onChange }) => {
  const leagues = [
    'Saint-Laurent Tuesday Men 7v7',
    'Letendre Thursday Men 7v7',
    'Berthiaume Sunday Mix 7v7',
  ];

  return (
    <FormControl id='league' fullWidth required sx={{ mt: 2 }}>
      <StyledInputLabel id='league-label'>League</StyledInputLabel>
      <StyledSelect
        labelId='league-label'
        id='league-select'
        value={league}
        onChange={onChange}
        label='League'
      >
        {leagues.map((league) => (
          <MenuItem key={league} value={league}>
            {league}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default LeagueSelect;
