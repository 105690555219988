import React from 'react';
const Home = () => {

  React.useEffect(() => {
    window.location.href = '/pug';
  }, []);

  return (
    <div></div>
  );
};

export default Home;
